/* eslint-disable */
import * as actionTypes from './actionTypes';
import { commonService, userService, userFormService } from '../services';

export const getProviders = () => (dispatch) => {
  commonService.getProviders()
    .then(
      (response) => {
        const providers = response.data;
        dispatch({ type: actionTypes.GET_PROVIDER_SUCCESS, providers });
      },
      () => {
        dispatch({ type: actionTypes.GET_PROVIDER_FAILURE });
      }
    );
  dispatch({ type: actionTypes.GET_PROVIDER_SUCCESS });
};

export const getFacilities = () => (dispatch) => {
  commonService.getFacilityAddresses()
    .then(
      (response) => {
        const facilities = response.data;
        dispatch({ type: actionTypes.GET_FACILITY_SUCCESS, facilities });
      },
      () => {
        dispatch({ type: actionTypes.GET_FACILITY_FAILURE });
      }
    );
};

export const getFacilitiesUnauth = (businessId) => (dispatch) => {
  commonService.getFacilityAddressesUnauth(businessId)
    .then(
      (response) => {
        const facilities = response.data;
        dispatch({ type: actionTypes.GET_FACILITY_UNAUTH_SUCCESS, facilities });
      },
      () => {
        dispatch({ type: actionTypes.GET_FACILITY_UNAUTH_FAILURE });
      }
    );
};

export const getCollaborationsUnauth = (businessId) => (dispatch) => {
  commonService.getCollaborationsUnauth(businessId)
    .then(
      (response) => {
        const collaborations = response.data;
        dispatch({ type: actionTypes.GET_COLLABORATIONS_UNAUTH_SUCCESS, collaborations });
      },
      () => {
        dispatch({ type: actionTypes.GET_COLLABORATIONS_UNAUTH_FAILURE });
      }
    );
};

export const getProviderUnauth = (businessId) => (dispatch) => {
  commonService.getProviderUnauth(businessId)
    .then(
      (response) => {
        const providers = response.data;
        dispatch({ type: actionTypes.GET_PROVIDER_UNAUTH_SUCCESS, providers });
      },
      () => {
        dispatch({ type: actionTypes.GET_PROVIDER_UNAUTH_FAILURE });
      }
    );
};

export const getAffiliations = () => (dispatch) => {
  commonService.getAffiliations()
    .then(
      (response) => {
        const affiliations = response.data;
        dispatch({ type: actionTypes.GET_AFFILIATION_SUCCESS, affiliations });
      },
      () => {
        dispatch({ type: actionTypes.GET_AFFILIATION_FAILURE });
      }
    );
};

export const fetchAllBusiness = () => (dispatch) => {
  commonService.getAllBusiness()
    .then((response) => {
      const business = response.data;
      dispatch({ type: actionTypes.FETCH_ALL_BUSINESS_SUCCESS, business });
    });
};

export const fetchCalendarEvents = (facilityId) => (dispatch) => {
  commonService.fetchCalendarEvents(facilityId)
    .then((response) => {
      const unavailableSlots = response.data;
      dispatch({ type: actionTypes.FETCH_UNAVAILABLE_SLOTS_SUCCESS, unavailableSlots });
    });
};

export const fetchUserCalendarEvents = (facility) => (dispatch) => {
  commonService.fetchUserCalendarEvents(facility)
    .then((response) => {
      const events = response.data;
      dispatch({ type: actionTypes.FETCH_CALENDAR_EVENTS_SUCCESS, events });
    });
};

export const updateCalendarSlot = (changeDate) => (dispatch) => {
  commonService.putUserCalendarEvent(changeDate)
    .then((response) => {
      const events = response.data;
      dispatch({ type: actionTypes.FETCH_CALENDAR_EVENTS_SUCCESS, events });
    });
};

export const changeMedicalHistory = (medicalHistory) => (dispatch) => {
  dispatch({ type: actionTypes.CHANGE_MEDICAL_HISTORY_DATA, medicalHistory });
};

export const getPublicPatientForm = (collaborationId) => (dispatch) => {
  commonService.getPublicPatientForm(collaborationId)
    .then((response) => {
      dispatch({ type: actionTypes.FETCH_MEDICAL_HISTORY_FORM_DATA_SUCCESS, data: response.data });
    });
};
export const getUserForm = (collaborationId) => (dispatch) => {
  commonService.getUserForm(collaborationId)
    .then((response) => {
      dispatch({ type: actionTypes.FETCH_MEDICAL_HISTORY_FORM_DATA_SUCCESS, data: response.data });
    });
};

export const changeSelectedDateOfPatientCalendar = (selectedDate) => (dispatch) => {
  dispatch({ type: actionTypes.CHANGE_SELECTED_DATE_OF_PATIENT_CALENDAR, selectedDate });
};

export const changeSelectedDateOfTelehealthCalendar = (selectedDate) => (dispatch) => {
  dispatch({ type: actionTypes.CHANGE_SELECTED_DATE_OF_TELEHEALTH_CALENDAR, selectedDate });
};

export const changePatientInfoMedicalHistory = (patientInfo) => (dispatch) => {
  dispatch({ type: actionTypes.CHANGE_PATIENT_INFO_MEDICAL_HISTORY, patientInfo });
};

export const getCollaborationUnauth = (collaborationId) => (dispatch) => {
  commonService.getCollaborationUnauth(collaborationId)
    .then((response) => {
      dispatch({ type: actionTypes.GET_COLLABORATION_UNAUTH_SUCCESS, data: response.data });
    });
};

export const getUserFormByCollaborationId = (collaborationId) => (dispatch) => {
  userFormService
    .getUserFormByCollaborationId(collaborationId)
    .then((response) => {
      dispatch({
        type: actionTypes.GET_ALL_USER_FORM_SUCCESS_APPEND,
        data: response.data
      });
    }),
    () => {
      dispatch({ type: actionTypes.GET_ALL_USER_FORM_FAILURE });
    };
};

export const updateToastConfig = (data) => (dispatch) => {
  dispatch({ type: actionTypes.UPDATE_TOAST_CONFIG, data });
};

export const fetchBasicDoctorInfo = (emails) => (dispatch) => {
  commonService.basicDoctorInfoUnauth(emails)
    .then((response) => {
      dispatch({ type: actionTypes.FETCH_BASIC_DOCTOR_INFO_UNAUTH_SUCCESS, data: response.data });
    });
};

export const updateShowPreview = (data) => (dispatch) => {
  dispatch({ type: actionTypes.CHANGE_SHOW_PREVIEW, data });
};

export const updateDiagnosis = (data) => (dispatch) => {
  dispatch({ type: actionTypes.UPDATE_DIAGNOSIS, data });
};

export const updateFilterProvider = (data) => (dispatch) => {
  dispatch({ type: actionTypes.UPDATE_FILTER_PROVIDER, data });
};

export const addNewDoctorsReferral = (data) => (dispatch) => {
  dispatch({ type: actionTypes.ADD_NEW_DOCTOR_REFERRAL_NO_LOGIN, data });
};

export const getUserByBusinessId = (businessId) => (dispatch) => {
  userService.getUserByBusinessId(businessId)
    .then(
      (response) => {
        const userOfBusiness = response.data;
        dispatch({ type: actionTypes.GET_USER_BY_BUSSINESS_ID_SUCCESS, userOfBusiness });
      },
      () => {
        dispatch({ type: actionTypes.GET_USER_BY_BUSSINESS_ID_FAILURE });
      }
    );
};
