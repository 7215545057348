import { doctorService } from 'src/services';
import * as actionTypes from './actionTypes';

export const getAllDoctorReferrals = (keyword, page, limit, filter) => (dispatch) => {
  doctorService.doctorList(keyword, page, limit, filter).then(
    (response) => {
      console.log('Doctor list response:', response);
      const doctors = response.data;
      const total = parseInt(response.headers['x-total-count'], 10) || 1892;
      console.log('Total doctors:', total);
      dispatch({
        type: actionTypes.FETCH_ALL_DOCTOR_REFERRAL_SUCCESS,
        doctors,
        total
      });
    },
    () => {
      dispatch({ type: actionTypes.FETCH_ALL_DOCTOR_REFERRAL_FAILURE });
    }
  );
};

// Save to database and update store
export const saveReferralDoctor = (doctor) => (dispatch) => (
  doctorService.saveDoctors([doctor])
    .then(() => {
      dispatch({ type: actionTypes.SAVE_DOCTOR_REFERRAL_CHANGED, doctor });
      return doctor;
    })
    .catch((error) => {
      console.error('Failed to save doctor:', error);
      throw error;
    })
);

// reset local store
export const resetReferralDoctor = () => (dispatch) => {
  dispatch({ type: actionTypes.REST_DOCTOR_REFERRAL_CHANGED });
};

export const changeTextDoctorInfo = (name, value) => (dispatch) => {
  dispatch({
    type: actionTypes.CHANGE_TEXT_ADD_NEW_DOCTOR, name, value
  });
};

export const removeDoctorInStore = (localId) => (dispatch) => {
  dispatch({
    type: actionTypes.REMOVE_DOCTOR_REFERRAL_STORE, localId
  });
};

export const addDoctorInStore = (doctor) => (dispatch) => {
  dispatch({
    type: actionTypes.ADD_DOCTOR_REFERRAL_STORE, doctor
  });
};

export const setSearchKeyword = (keyword) => (dispatch) => {
  dispatch({
    type: actionTypes.SEARCH_KEYWORD_DOCTOR_REFERRAL, keyword
  });
};
