/* eslint-disable max-len */
import { axiosInstance } from 'src/helpers/auth-header';
import stringFormatter from '../utils/stringFormatter';

const endpoints = {
  getAppPatient: stringFormatter.formatUrl('/api/app/patient/'),
};

const getAppPatientById = (Id) => axiosInstance.get(`${endpoints.getAppPatient}${Id}`);
const updateAppPatient = (patient) => axiosInstance.put(`${endpoints.getAppPatient}`, patient);

export const appPatientService = {
  getAppPatientById,
  updateAppPatient,
};

export default {};
