import axios from 'axios';

export const authHeader = (initToken) => {
  const token = !initToken ? localStorage.getItem('token') : initToken;

  if (token) {
    return { 'x-access-token': `${token}` };
  }
  return null;
};

const callApi = (method, url, payload = {}, initToken = null) => {
  const configData = {
    method,
    url,
    data: payload,
    headers: authHeader(initToken)
  };
  // console.table(configData);
  const header = authHeader(initToken);
  // Add a request interceptor
  axios.interceptors.request.use(
    (config) =>
      // Do something before request is sent
      // eslint-disable-next-line implicit-arrow-linebreak
      config,
    (error) => {
      // Do something with request error
      // eslint-disable-next-line implicit-arrow-linebreak
      if (error.response && error.response.status === 401) {
        localStorage.clear();
      }
      return Promise.reject(error);
    }
  );

  // Add a response interceptor
  axios.interceptors.response.use(
    (response) =>
      // Do something with response data
      // eslint-disable-next-line implicit-arrow-linebreak
      response,
    (error) =>
      // Do something with response error
      // eslint-disable-next-line implicit-arrow-linebreak
      Promise.reject(error)
  );

  const instance = axios.create({
    headers: header
  });

  switch (method) {
    case 'GET':
      return instance.get(url);
    case 'POST':
      return instance.post(url, payload);
    case 'PUT':
      return instance.put(url, payload);
    case 'DEL':
      return instance.delete(url);
    default:
      return instance.get(url);
  }
};

export const axiosInstance = {
  get: (url, initToken) => callApi('GET', url, null, initToken),
  put: (url, payload, initToken) => callApi('PUT', url, payload, initToken),
  post: (url, payload, initToken) => callApi('POST', url, payload, initToken),
  delete: (url) => callApi('DEL', url)
};
