/* eslint-disable */
const isPositiveInteger = (str) => {
  if (typeof str !== 'string') {
    return false;
  }
  const num = Number(str);

  if (Number.isInteger(num) && num >= 0) {
    return true;
  }
  return false;
};

// Unused after store 4 value into 1 column (referral_required)
const getLastUpdatedKey = (data) => {
  const order = [
    'referral_required_yes',
    'referral_required_no',
    'referral_received_yes',
    'referral_received_no'
  ];

  let lastUpdatedKey = null;
  let lastUpdatedTime = null;

  for (let i = 0; i < order.length; i++) {
    const key = order[i];
    if (data?.hasOwnProperty(key) && data[key].update_at) {
      const updateTime = new Date(data[key].update_at);
      if (!lastUpdatedTime || updateTime > lastUpdatedTime) {
        lastUpdatedKey = key;
        lastUpdatedTime = updateTime;
      }
    }
  }

  if (lastUpdatedKey) {
    if (lastUpdatedKey.includes('required')) {
      return true;
    }
    if (lastUpdatedKey.includes('received')) {
      return false;
    }
  }

  return null;
};

const filterLatestObject = (array) => {
  const sortedArray = array.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  const latestObject = sortedArray[0];
  return latestObject;
};

const delay = (milliseconds) => new Promise((resolve) => setTimeout(resolve, milliseconds));

const checkPatientIdType = (id) => {
  if (/^\d+$/.test(id)) return 'INT';
  if (/^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i.test(id)) return 'UUID';
};

module.exports = {
  isPositiveInteger,
  getLastUpdatedKey,
  filterLatestObject,
  delay,
  checkPatientIdType
};
