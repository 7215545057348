/* eslint-disable */
import { appPatientService } from 'src/services';
import * as actionTypes from './actionTypes';
export const getAppPatient = (id) => (dispatch) => {
  appPatientService.getAppPatientById(id)
    .then((data) => {
      dispatch({ type: actionTypes.GET_APP_PATIENT_BY_ID_SUCCESS, patient: data.data });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.GET_APP_PATIENT_BY_ID_FAILURE,
        serverErrorMessage: error.response.data.message
      });
    });
};


export const updateAppPatient = (patient, history, fn) => (dispatch) => {
  appPatientService.updateAppPatient(patient)
    .then(() => {
      dispatch({ type: actionTypes.UPDATE_APP_PATIENT_SUCCESS });
      if (fn) {
        fn(patient);
      }
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.UPDATE_PATIENT_FAILURE,
        serverErrorMessage: error.response.data.message
      });
    })
    .finally(() => {
      // const editMode = patient.goTo.includes('edit-patient');
      // history(editMode ? '/preps/appointments/a25ca1bd-7b62-42ed-9f3b-9e4dc35d9d71' : patient.goTo);
    });
};