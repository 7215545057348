import { postOpChecklistService } from 'src/services';
import * as actionTypes from './actionTypes';

export const getAllPostOpChecklist = (keyword, page, limit, filter) => (dispatch) => {
  postOpChecklistService.postOpChecklist(keyword, page, limit, filter).then(
    (response) => {
      const patients = response.data || [];
      console.log('[POST-OP-CHECKLIST] patients:', patients);
      const total = parseInt(response.headers['x-total-count'], 10) || patients.length;
      console.log('Total patients:', total);
      dispatch({
        type: actionTypes.FETCH_ALL_POST_OP_CHECKLIST_SUCCESS,
        patients,
        total
      });
    },
    () => {
      dispatch({ type: actionTypes.FETCH_ALL_POST_OP_CHECKLIST_FAILURE });
    }
  );
};

// Save to database and update store
export const savePostOpChecklist = (patient) => (dispatch) => (
  postOpChecklistService.savePostOpChecklist([patient])
    .then(() => {
      dispatch({ type: actionTypes.SAVE_POST_OP_CHECKLIST_CHANGED, patient });
      return patient;
    })
    .catch((error) => {
      console.error('Failed to save patient:', error);
      throw error;
    })
);

// reset local store
export const resetPostOpChecklist = () => (dispatch) => {
  dispatch({ type: actionTypes.REMOVE_POST_OP_CHECKLIST_STORE });
};

export const changeTextPostOpChecklistNote = (name, value) => (dispatch) => {
  dispatch({
    type: actionTypes.CHANGE_TEXT_ADD_NOTE, name, value
  });
};

export const setSearchPatientNameKeyword = (keyword) => (dispatch) => {
  dispatch({
    type: actionTypes.SEARCH_KEYWORD_POST_OP_CHECKLIST, keyword
  });
};
