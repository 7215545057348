/* eslint-disable */
import * as actionTypes from 'src/actions/actionTypes';

const initialState = {
  userForms: [],
  loading: true,
  openAlert: false,
  userFromDropdowns: []
};

const userFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.USER_FORM_PENDING:
      return {
        ...state,
        loading: true,
        openAlert: false
      };

    case actionTypes.GET_ALL_USER_FORM_SUCCESS:
      const dropDownItems = (action.userForms || []).map((userForm) => {
        if (!userForm?.collaboration) return null;
        return {
          name: userForm.collaboration.name || '',
          link: `preps/${userForm.collaboration.shortName || ''}/${userForm.collaborationId || ''}`
        };
      }).filter(Boolean);

      return {
        ...state,
        loading: false,
        userForms: action.userForms || [],
        userFromDropdowns: [
          {
            name: 'Create New Form',
            link: '/preps/user-form/create'
          },
          {
            name: 'Edit Forms',
            link: '/allpreps'
          },
          {
            name: 'Schedule Surgery',
            link: '/preps/surgery/create-patient/a25ca1bd-7b62-42ed-9f3b-9e4dc35d9d71'
          },
          {
            name: 'Surgery Tracker',
            link: '/preps/surgery/2f11a238-2939-4b9c-ab0d-57244cf3bf3a'
          },
          {
            name: 'Surgery Orders',
            link: '/preps/surgical_order_request/2f11a238-2939-4b9c-ab0d-57244cf3bf3a'
          },
          ...dropDownItems
        ]
      };

    case actionTypes.GET_ALL_USER_FORM_SUCCESS_APPEND:
      return {
        ...state,
        loading: false,
        userForms: [
          ...state.userForms.filter(userForm => userForm.localId !== action.data.localId),
          { localId: action.data.localId, ...action.data }
        ]
      };

    case actionTypes.GET_ALL_USER_FORM_FAILURE:
      return {
        ...state,
        loading: false,
        userForms: []
      };

    default:
      return { ...state };
  }
};

export default userFormReducer;
