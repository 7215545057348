/* eslint-disable */
import * as actionTypes from '../actions/actionTypes';

const initialState = {
  facilities: [],
  collaborations: [],
  collaboration: {},
  providers: [],
  verifyQuestions: [],
  affiliations: [],
  allBusiness: [],
  defaultEvents: [],
  unavailableSlots: [],
  publicFormValid: false,
  medicalHistory: {},
  medicalHistoryFormData: [],
  newDoctorsReferral: [],
  selectedDate: null,
  selectedTelehealthDate: null,
  patientInfo: {},
  toastConfig: {
    open: false,
    message: '',
    type: 'info'
  },
  basicDoctorInfo: [],
  showPreview: false,
  diagnosis: '',
  filterProvider: {
    doctorId: '',
    location: ''
  },
  userOfBusiness: []
};

const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_FACILITY_SUCCESS:
      return {
        ...state,
        facilities: action.facilities
      };

    case actionTypes.GET_FACILITY_UNAUTH_SUCCESS:
      return {
        ...state,
        facilities: action.facilities
      };

    case actionTypes.GET_COLLABORATIONS_UNAUTH_SUCCESS:
      return {
        ...state,
        collaborations: action.collaborations
      };

    case actionTypes.GET_PROVIDER_SUCCESS:
      return {
        ...state,
        providers: action.providers
      };

    case actionTypes.GET_PROVIDER_UNAUTH_SUCCESS:
      return {
        ...state,
        providers: action.providers
      };

    case actionTypes.GET_VERIFY_QUESTION_SUCCESS:
      return {
        ...state,
        verifyQuestions: action.verifyQuestions
      };

    case actionTypes.GET_AFFILIATION_SUCCESS:
      return {
        ...state,
        affiliations: action.affiliations
      };

    case actionTypes.FETCH_ALL_BUSINESS_SUCCESS:
      return {
        ...state,
        allBusiness: action.business
      };

    case actionTypes.FETCH_CALENDAR_EVENTS_SUCCESS:
      return {
        ...state,
        defaultEvents: action.events
      };

    case actionTypes.CHANGE_MEDICAL_HISTORY_DATA:
      return {
        ...state,
        medicalHistory: action.medicalHistory
      };
    case actionTypes.EMPTY_MEDICAL_HISTORY_DATA:
      return {
        ...state,
        medicalHistory: {},
        medicalHistoryFormData: []
      };

    case actionTypes.FETCH_MEDICAL_HISTORY_FORM_DATA_SUCCESS:
      return {
        ...state,
        medicalHistoryFormData: action.data
      };

    case actionTypes.CHANGE_SELECTED_DATE_OF_PATIENT_CALENDAR:
      return {
        ...state,
        selectedDate: action.selectedDate
      };

    case actionTypes.CHANGE_SELECTED_DATE_OF_TELEHEALTH_CALENDAR:
      return {
        ...state,
        selectedTelehealthDate: action.selectedDate
      };

    case actionTypes.FETCH_UNAVAILABLE_SLOTS_SUCCESS:
      return {
        ...state,
        unavailableSlots: action.unavailableSlots
      };

    case actionTypes.CHANGE_PATIENT_INFO_MEDICAL_HISTORY:
      return {
        ...state,
        patientInfo: action.patientInfo
      };

    case actionTypes.GET_COLLABORATION_UNAUTH_SUCCESS:
      return {
        ...state,
        collaboration: action.data
      };

    case actionTypes.UPDATE_TOAST_CONFIG:
      return {
        ...state,
        toastConfig: action.data
      };

    case actionTypes.FETCH_BASIC_DOCTOR_INFO_UNAUTH_SUCCESS:
      return {
        ...state,
        basicDoctorInfo: action.data
      };

    case actionTypes.CHANGE_SHOW_PREVIEW:
      return {
        ...state,
        showPreview: action.data
      };

    case actionTypes.UPDATE_DIAGNOSIS:
      return {
        ...state,
        diagnosis: action.data
      };

    case actionTypes.UPDATE_FILTER_PROVIDER:
      return {
        ...state,
        filterProvider: action.data
      };

    case actionTypes.ADD_NEW_DOCTOR_REFERRAL_NO_LOGIN:
      return {
        ...state,
        newDoctorsReferral: action.data
      };

    case actionTypes.GET_USER_BY_BUSSINESS_ID_SUCCESS:
      return {
        ...state,
        userOfBusiness: action.userOfBusiness
      };
    case actionTypes.GET_USER_BY_BUSSINESS_ID_FAILURE:
      return {
        ...state,
        userOfBusiness: []
      };

    default:
      return { ...state };
  }
};

export default commonReducer;
