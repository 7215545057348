/* eslint-disable */
import * as actionTypes from 'src/actions/actionTypes';
import moment from 'moment';

const initialState = {
  patients: [],
  patient: {
    localId: '',
    collaborationId: '',
    businessLocationId: '',
    businessId: '',
    business: {},
    businessLocation: {},
    procedureDoctorId: '',
    telehealthDoctorId: '',
    provider: {},
    procedureDate: null,
    procedureTime: '',
    procedureTimePicker: '',
    profileImage: '',
    firstName: '',
    lastName: '',
    dateOfBirth: null,
    mobilePhone: '',
    email: '',
    streetAddress: '',
    zipCode: '',
    verifyMobile: false,
    patientConsent: false,
    confirmed: false,
    sendWelcomeSMS: false,
    visitType: '',
    visitOfficeId: '',
    salutation: '',
    sendToPatientAction: 0,
    diagnosis: '',
    fax: false,
    referredBy: '',
    referral: ''
  },
  newPatient: {
    localId: '',
    collaborationId: '',
    businessId: '',
    businessLocationId: '',
    procedureDoctorId: '',
    telehealthDoctorId: '',
    procedureDate: null,
    procedureTime: '',
    procedureTimePicker: '',
    profileImage: '',
    firstName: '',
    lastName: '',
    dateOfBirth: null,
    mobilePhone: '',
    email: '',
    streetAddress: '',
    zipCode: '',
    verifyMobile: false,
    patientConsent: false,
    timezone: null,
    forceCreate: false,
    city: '',
    state: '',
    confirmed: false,
    sendWelcomeSMS: false,
    visitType: '',
    visitOfficeId: '',
    salutation: '',
    sendToPatientAction: 0,
    diagnosis: '',
    fax: false,
    referredBy: '',
    referredDoctorId: '',
    createBy: '',
    createByFirstName: '',
    createByLastName: '',
    override: false,
    referralRequired: null,
    referralReceived: null,
    sameDay: false
  },
  isCreated: false,
  toggleAddPatient: false,
  selectedPatientId: '',
  hasServerError: false,
  serverErrorType: 'error',
  enableForceChangeButton: false,
  serverErrorMessage: '',
  verify: {},
  addPatientUnauthSuccess: false,
  patientFormSubmitSuccessfully: false,
  appointments: [],
  isToday: false,
  filterDate: ''
};

const patientReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TURN_ON_MODAL_ADD_PATIENT:
      return {
        ...state,
        toggleAddPatient: true,
        newPatient: initialState.newPatient
      };

    case actionTypes.TURN_OFF_MODAL_ADD_PATIENT:
      return {
        ...state,
        toggleAddPatient: false,
        newPatient: initialState.newPatient
      };

    case actionTypes.PATIENT_SAVING:
      return {
        ...state,
        isCreated: false,
        saving: true
      };

    case actionTypes.EDIT_PATIENT:
      return {
        ...state,
        isCreated: false,
        newPatient: {
          ...state.newPatient,
          ...action.patient,
          dateOfBirth: action.patient.dateOfBirth
            ? moment(action.patient.dateOfBirth).format('MM/DD/YYYY')
            : null,
          procedureTimePicker: action.patient.procedureTime
            ? moment(action.patient.procedureTime).format('HH:mm')
            : null
        }
      };
    case actionTypes.UPDATE_PATIENT_TODAY_FILTER:
      return {
        ...state,
        isToday: action.isToday
      };
    case actionTypes.UPDATE_PATIENT_DATE_FILTER:
      return {
        ...state,
        filterDate: action.filterDate
      };
    case actionTypes.CHANGE_PATIENT_FIELD:
      return {
        ...state,
        newPatient: {
          ...state.newPatient,
          [action.name]: action.value
        }
      };

    case actionTypes.ADD_PATIENT_SUCCESS:
      return {
        ...state,
        isCreated: true,
        saving: false,
        toggleAddPatient: false,
        hasServerError: false,
        serverErrorMessage: null
      };

    case actionTypes.UPDATE_PATIENT_SUCCESS:
      return {
        ...state,
        isCreated: true,
        saving: false,
        toggleAddPatient: false,
        hasServerError: false,
        serverErrorMessage: null
      };

    case actionTypes.ADD_PATIENT_FAILURE:
      return {
        ...state,
        isCreated: false,
        hasServerError: true,
        saving: false,
        serverErrorMessage: action.serverErrorMessage
      };

    case actionTypes.UPDATE_PATIENT_FAILURE:
      return {
        ...state,
        isCreated: false,
        hasServerError: true,
        serverErrorMessage: action.serverErrorMessage
      };

    case actionTypes.GET_ALL_PATIENT_SUCCESS:
      return {
        ...state,
        patients: action.patients
      };

    case actionTypes.EMPTY_PATIENT_LIST:
      return {
        ...state,
        patients: []
      };

    case actionTypes.EMPTY_NEW_PATIENT:
      return {
        ...state,
        newPatient: {
          ...state.newPatient,
          firstName: '',
          lastName: '',
          dateOfBirth: null,
          mobilePhone: '',
          email: ''
        }
      };

    case actionTypes.GET_ALL_PATIENT_SUCCESS_APPEND: {
      let filterPatientToday = [];
      if (state.filterDate !== '') {
        // eslint-disable-next-line array-callback-return,consistent-return
        filterPatientToday = action.patients.filter((item) => {
          const dayItem = moment(item.parseTelehealthDate).format('YYYY-MM-DD');
          if (dayItem === state.filterDate) {
            return item;
          }
        });
        return {
          ...state,
          patients: [...state.patients, ...filterPatientToday]
        };
      }
      return {
        ...state,
        patients: [...state.patients, ...action.patients]
      };
    }

    case actionTypes.GET_ALL_PATIENT_FAILURE:
      return {
        ...state,
        patients: []
      };

    case actionTypes.SELECTED_PATIENT_ID:
      return {
        ...state,
        selectedPatientId: action.selectedPatientId
      };

    case actionTypes.SELECTED_PATIENT:
      return {
        ...state,
        patient: {
          localId: action.patient.localId,
          collaborationId: action.patient.collaborationId,
          businessId: action.patient.businessId,
          businessLocationId: action.patient.businessLocationId,
          business: action.patient.business,
          businessLocation: action.patient.businessLocation,
          procedureDoctorId: action.patient.procedureDoctorId,
          telehealthDoctorId: action.patient.telehealthDoctorId,
          procedureDate: action.patient.procedureDate
            ? moment(action.patient.procedureDate).format('MM/DD/YYYY')
            : null,
          procedureTime: action.patient.procedureTime,
          profileImage: action.patient.profileImage,
          firstName: action.patient.firstName,
          lastName: action.patient.lastName,
          dateOfBirth: action.patient.dateOfBirth
            ? moment(action.patient.dateOfBirth).format('MM/DD/YYYY')
            : null,
          mobilePhone: action.patient.mobilePhone,
          email: action.patient.email,
          streetAddress: action.patient.streetAddress,
          zipCode: action.patient.zipCode,
          verifyMobile: action.patient.verifyMobile,
          patientConsent: action.patient.patientConsent,
          provider: action.patient.provider,
          addedByStaff: action.patient.addedByStaff,
          proposedTime: action.patient.proposedTime,
          parseTelehealthDate: action.patient.parseTelehealthDate,
          parseTelehealthTime: action.patient.parseTelehealthTime,
          telehealthTime: action.patient.telehealthTime,
          visitDoctor: action.patient.visitDoctor,
          visitOffice: action.patient.visitOffice,
          visitType: action.patient.visitType
        }
      };

    case actionTypes.GET_PATIENT_BY_ID_SUCCESS:

    case actionTypes.GET_APP_PATIENT_BY_ID_SUCCESS:
      return {
        ...state,
        patient: {
          localId: action.patient.id,
          // collaborationId: action.patient.collaborationId,
          businessId: action.patient.businessId,
          businessLocationId: action.patient.businessLocationId,
          procedureDoctorId: action.patient.procedureDoctorId,
          telehealthDoctorId: action.patient.telehealthDoctorId,
          procedureDate: action.patient.procedureDate
            ? moment(action.patient.procedureDate).format('MM/DD/YYYY')
            : null,
          procedureTime: action.patient.procedureTime,
          profileImage: action.patient.profileImage,
          firstName: action.patient.first_name,
          lastName: action.patient.name,
          dateOfBirth: action.patient.dob
            ? moment(action.patient.dob).format('MM/DD/YYYY')
            : null,
          mobilePhone: action.patient.mobilePhone,
          email: action.patient.email,
          streetAddress: action.patient.streetAddress,
          zipCode: action.patient.zipCode,
          verifyMobile: action.patient.verifyMobile,
          patientConsent: action.patient.patientConsent,
          business: action.patient.business,
          businessLocation: action.patient.businessLocation,
          provider: action.patient.provider,
          addedByStaff: action.patient.addedByStaff,
          proposedTime: action.patient.proposedTime
        }
      };

    case actionTypes.GET_PATIENT_VERIFY_QUESTION_SUCCESS:
      return {
        ...state,
        verify: {
          questionId: action.question.questionId,
          question: action.question.question.content,
          options: action.question.options
        }
      };

    case actionTypes.ENABLE_FORCE_CHANGE_BUTTON:
      return {
        ...state,
        enableForceChangeButton: action.data
      };

    case actionTypes.CHANGE_FORCE_CHANGE_VALUE:
      return {
        ...state,
        newPatient: {
          ...state.newPatient,
          forceCreate: action.data
        }
      };

    case actionTypes.CHANGE_CREATE_PATIENT_NOTI_TYPE:
      return {
        ...state,
        serverErrorType: action.data
      };

    case actionTypes.ADD_PATIENT_UNAUTH_SUCCESS:
      return {
        ...state,
        addPatientUnauthSuccess: true
      };

    case actionTypes.CHANGE_PROCEDURE_TIME:
      return {
        ...state,
        newPatient: {
          ...state.newPatient,
          [action.data.field]: action.data.value
        }
      };

    case actionTypes.CHANGE_PROCEDURE_TIME_PICKER:
      return {
        ...state,
        newPatient: {
          ...state.newPatient,
          procedureTimePicker: action.data
        }
      };

    case actionTypes.RESET_HAS_SERVER_ERROR_VALUE:
      return {
        ...state,
        hasServerError: action.value
      };

    case actionTypes.ADD_PATIENT_UNAUTH_FAILURE:
      return {
        ...state,
        isCreated: false,
        hasServerError: true,
        saving: false,
        serverErrorMessage: action.serverErrorMessage
      };

    case actionTypes.CHANGE_PATIENT_FORM_SUBMIT_SUCCESSFULLY_VALUE:
      return {
        ...state,
        patientFormSubmitSuccessfully: action.value
      };

    default:
      return { ...state };
  }
};

export default patientReducer;
