/* eslint-disable max-len */
import { axiosInstance } from 'src/helpers/auth-header';
import stringFormatter from '../utils/stringFormatter';

const endpoints = {
  postOpChecklist: stringFormatter.formatUrl('/api/post-op-checklist/list'),
  savePostOpChecklist: stringFormatter.formatUrl('/api/post-op-checklist/save-all'),
  updatePostOpChecklist: stringFormatter.formatUrl('/api/post-op-checklist/update')
};

const postOpChecklist = (keyword = '', page = 0, limit = 50, filter = '') => axiosInstance.get(`${endpoints.postOpChecklist}?search=${keyword}&filter=${filter}&page=${page}&limit=${limit}`);

const savePostOpChecklist = (objects) => {
  console.log('Service savePostOpChecklist payload:', objects);
  console.log('Service savePostOpChecklist status values:', objects.map((obj) => obj.status));
  return axiosInstance.put(`${endpoints.savePostOpChecklist}`, objects)
    .then((response) => {
      console.log('Service savePostOpChecklist response:', response);
      return response;
    })
    .catch((error) => {
      console.error('Service savePostOpChecklist error:', error);
      throw error;
    });
};

const updatePostOpChecklist = (object) => axiosInstance.put(`${endpoints.updatePostOpChecklist}/${object.localId}`, object);

export const postOpChecklistService = {
  postOpChecklist,
  savePostOpChecklist,
  updatePostOpChecklist
};

export default {};
