/* eslint-disable */
import * as actionTypes from 'src/actions/actionTypes';
import { uniqBy } from 'lodash-es';

const initialState = {
  patients: [],
  patientInfo: {
    firstName: '',
    lastName: '',
    operativeNote: false,
    pathologyReport: false,
    dischargeSummary: false,
    surgeryProgressNote: false,
    patientPathReview: false,
    opOrPathSent: false,
    referralLetterSent: false,
    notes: ''
  },
  keyword: '',
  total: 0
};

const postOpChecklistReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ALL_POST_OP_CHECKLIST_SUCCESS:
      return {
        ...state,
        patients: action.patients,
        total: action.total
      };
    case actionTypes.FETCH_ALL_POST_OP_CHECKLIST_FAILURE:
      return {
        ...state,
        patients: []
      };
    case actionTypes.SAVE_POST_OP_CHECKLIST_CHANGED: {
      const temp = [...state.patients];
      const index = temp.findIndex(
        (doc) => doc.localId === action.doctor.localId
      );
      if (index >= 0) {
        temp[index] = action.doctor;
      }
      return {
        ...state,
        patients: [...temp]
      };
    }
    case actionTypes.REST_POST_OP_CHECKLIST_CHANGED:
      return {
        ...state,
        patients: []
      };
    case actionTypes.SEARCH_KEYWORD_POST_OP_CHECKLIST:
      return {
        ...state,
        keyword: action.keyword
      };
    case actionTypes.CHANGE_TEXT_ADD_NOTE:
      // eslint-disable-next-line no-case-declarations
      const { name, value } = action;
      // eslint-disable-next-line no-param-reassign
      state.patientInfo = {
        ...state.patientInfo,
        [name]: value
      };
      return { ...state };

    case actionTypes.REMOVE_POST_OP_CHECKLIST_STORE:
      // eslint-disable-next-line no-case-declarations
      const temp = [...state.patients];
      // eslint-disable-next-line no-case-declarations
      const { localId } = action;
      // eslint-disable-next-line no-case-declarations
      const filtered = temp.filter((item) => item.localId !== localId);
      return {
        ...state,
        patients: [...filtered]
      };
    default:
      return { ...state };
  }
};
export default postOpChecklistReducer;
