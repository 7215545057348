/* eslint-disable max-len */
import { axiosInstance } from 'src/helpers/auth-header';
import stringFormatter from '../utils/stringFormatter';

const endpoints = {
  doctor: stringFormatter.formatUrl('/api/doctor'),
  doctorList: stringFormatter.formatUrl('/api/doctor/list'),
  saveDoctors: stringFormatter.formatUrl('/api/doctor/save-all'),
  createDoctor: stringFormatter.formatUrl('/api/doctor/create'),
  updateDoctor: stringFormatter.formatUrl('/api/doctor/update')
};

const doctorList = (keyword = '', page = 0, limit = 50, filter = '') => axiosInstance.get(`${endpoints.doctorList}?search=${keyword}&filter=${filter}&page=${page}&limit=${limit}`);

const saveDoctors = (objects) => {
  console.log('Service saveDoctors payload:', objects);
  console.log('Service saveDoctors status values:', objects.map((obj) => obj.status));
  return axiosInstance.put(`${endpoints.saveDoctors}`, objects)
    .then((response) => {
      console.log('Service saveDoctors response:', response);
      return response;
    })
    .catch((error) => {
      console.error('Service saveDoctors error:', error);
      throw error;
    });
};

const deleteDoctor = (localId) => axiosInstance.delete(`${endpoints.doctor}/${localId}`);
const createDoctor = (object) => axiosInstance.post(`${endpoints.createDoctor}`, object);
const updateDoctor = (object) => axiosInstance.put(`${endpoints.updateDoctor}/${object.localId}`, object);

export const doctorService = {
  doctorList,
  saveDoctors,
  deleteDoctor,
  createDoctor,
  updateDoctor
};

export default {};
