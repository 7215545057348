/* eslint-disable */
import * as actionTypes from 'src/actions/actionTypes';
import { uniqBy } from 'lodash-es';

const initialState = {
  referrals: [],
  doctorInfo: {
    doctorName: '',
    firstName: '',
    lastName: '',
    providerSuffix: '',
    phoneNumber: '',
    faxNumber: '',
    practiceName: '',
    address: '',
    status: '',
    doctorType: '',
    mobileNumber: ''
  },
  keyword: '',
  total: 0
};

const doctorReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ALL_DOCTOR_REFERRAL_SUCCESS:
      return {
        ...state,
        referrals: action.doctors,
        total: action.total
      };
    case actionTypes.FETCH_ALL_DOCTOR_REFERRAL_FAILURE:
      return {
        ...state,
        referrals: []
      };
    case actionTypes.SAVE_DOCTOR_REFERRAL_CHANGED: {
      const temp = [...state.referrals];
      const index = temp.findIndex(
        (doc) => doc.localId === action.doctor.localId
      );
      if (index >= 0) {
        temp[index] = action.doctor;
      }
      return {
        ...state,
        referrals: [...temp]
      };
    }
    case actionTypes.REST_DOCTOR_REFERRAL_CHANGED:
      return {
        ...state,
        referrals: []
      };
    case actionTypes.SEARCH_KEYWORD_DOCTOR_REFERRAL:
      return {
        ...state,
        keyword: action.keyword
      };
    case actionTypes.CHANGE_TEXT_ADD_NEW_DOCTOR:
      // eslint-disable-next-line no-case-declarations
      const { name, value } = action;
      // eslint-disable-next-line no-param-reassign
      state.doctorInfo = {
        ...state.doctorInfo,
        [name]: value
      };
      return { ...state };

    case actionTypes.REMOVE_DOCTOR_REFERRAL_STORE:
      // eslint-disable-next-line no-case-declarations
      const temp = [...state.referrals];
      // eslint-disable-next-line no-case-declarations
      const { localId } = action;
      // eslint-disable-next-line no-case-declarations
      const filtered = temp.filter((item) => item.localId !== localId);
      return {
        ...state,
        referrals: [...filtered]
      };
    case actionTypes.ADD_DOCTOR_REFERRAL_STORE:
      // eslint-disable-next-line no-case-declarations
      const resetDoctorInfo = {
        doctorName: '',
        firstName: '',
        lastName: '',
        providerSuffix: '',
        phoneNumber: '',
        faxNumber: '',
        practiceName: '',
        address: '',
        status: '',
        doctorType: '',
        mobileNumber: ''
      };
      // eslint-disable-next-line no-case-declarations
      const { doctor } = action;
      return {
        ...state,
        doctorInfo: resetDoctorInfo,
        referrals: [doctor, ...state.referrals]
      };
    default:
      return { ...state };
  }
};
export default doctorReducer;
